import { ResponseWrapper } from '@fullyops/data/base/entities/responses/response-wrapper';
import { UiServiceV2 } from './ui.service-v2.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import { UserInput, UserResponse } from '../../data/api/types/User';
import { BehaviorSubject } from 'rxjs';

type Roles = 'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'SALESMAN' | 'INSTALLER';

type QueryParameters = {
  active?: boolean;
  createdBy?: string;
  email?: string;
  includeDeleted?: boolean;
  order?: 'ASC' | 'DESC';
  orderBy?: string;
  page?: number;
  pageNumber?: number;
  pageSize?: number;
  roles?: Roles[];
  size?: number;
  updatedBy?: string;
  username?: string;
};

export type UpdateUserPartData = {
  userId: string;
  partId: string;
  quantity: number;
};

@Injectable({
  providedIn: 'root',
})
export class UserControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private readonly url = this.config.url + 'users';

  getUsers(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    return this.http.get<ResponseWrapper<UserResponse>>(`${this.url}${query}`);
  }

  getUsersAsListOptions(queryParameters?: QueryParameters) {
    const usersList = new BehaviorSubject<{ id: string; name: string }[]>([]);
    this.getUsers({ queryParameters }).subscribe((res) => {
      usersList.next(
        res.results.map((user) => {
          return {
            id: user.id,
            name: `${user.firstName} ${user.lastName} `,
          };
        })
      );
    });

    return usersList;
  }

  getCurrentUser() {
    return this.http.get<UserResponse>(`${this.url}/me`);
  }

  updateUserById(params: { id: string; user: UserInput }) {
    return this.http.patch<UserResponse>(
      `${this.url}/${params.id}`,
      params.user
    );
  }

  updateUserProfilePicture(params: { id: string; file: File }) {
    const data = new FormData();
    data.append('file', params.file);

    return this.http.post(`${this.url}/${params.id}/profile-picture`, data);
  }

  createUser(params: { newUser: UserInput }) {
    return this.http.post<UserResponse>(this.url, params.newUser);
  }

  getUserById(params: { id: string }) {
    return this.http.get<UserResponse>(`${this.url}/${params.id}`);
  }

  deleteUserById(params: { id: string }) {
    return this.http.delete(`${this.url}/${params.id}`);
  }
}
