<crm-expansion-panel
  title="{{ 'history' | i18nextCap }}"
  [hideIfNotAllowed]="historyPermissions"
>
  <div class="timeline-container" [@inOut]="'in'">
    <ng-container
      *ngIf="history$ | async | annotateWithCompletedFlag as historyList"
    >
      <ul class="timeline" *ngIf="historyList.length">
        <li *ngFor="let historyItem of historyList">
          @if (historyItem.type === 'ACTIVITY') {
          <crm-history-activity
            [activity]="historyItem.activity"
            (openActivityEvent)="openActivityEvent.emit($event)"
            (openTicketEvent)="openTicketEvent.emit($event)"
          ></crm-history-activity>
          } @else {
          <crm-history-ticket
            [ticket]="historyItem.ticket"
            [type]="historyItem.type"
            (openActivityEvent)="openActivityEvent.emit($any($event))"
            (openTicketEvent)="openTicketEvent.emit($event)"
          ></crm-history-ticket>
          }
        </li>
      </ul>
      <p class="no-activities" *ngIf="!historyList.length">
        {{ 'noActivities' | i18nextCap }}
      </p>
    </ng-container>
  </div>
</crm-expansion-panel>
