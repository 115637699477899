import { Pipe } from '@angular/core';
import { HistoryItem } from '@fullyops/data/history/history';
import * as moment from 'moment';

@Pipe({ standalone: true, name: 'annotateWithCompletedFlag' })
export class AnnotateWithCompletedFlagPipe {
  transform(hs: HistoryItem[]) {
    if (hs == null) {
      return null;
    }

    const now = moment();
    return hs.map((h) => {
      if (h.type === 'ACTIVITY') {
        return {
          ...h,
          activity: {
            ...h.activity,
            completed:
              h.activity.startsAt != null &&
              moment(h.activity.startsAt).isBefore(now),
          },
        };
      }
      return {
        ...h,
        ticket: {
          ...h.ticket,
          completed:
            h.ticket.date != null && moment(h.ticket.date).isBefore(now),
        },
      };
    });
  }
}
