import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_SERVICE_CONFIG,
  IApiServiceConfig,
} from '../base/configuration/api-service-config';
import { MiniEquipment } from './equipment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiEquipmentsAdapterService {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  getAllByCompany(companyId: string): Observable<MiniEquipment[]> {
    return this.http.get<MiniEquipment[]>(
      this.config.url + 'clients/equipments/names',
      { params: { companyId } }
    );
  }
}
