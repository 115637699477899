import { InterventionResponse } from './../../api/types/Intervention';
import { ISerialisedTicketSupportMultimachine } from './serialisations/ISerialisedTicketSupportMultimachine';
import { Contact } from './Contact';
import { Company } from './Company';
import { User } from './User';
import { UnsavedTicketSupportMultimachine } from './UnsavedTicketSupportMultimachine';
import { ClientInfo } from './ClientInfo';
import { TicketRequest } from './TicketRequest';
import { Status } from './Status';
import { WorkPeriod } from './WorkPeriod';
import { TravelResponse } from '../../api/types/Travel';
import { MiniEquipment } from '@fullyops/data/equipments/equipment';

export class TicketSupportMultimachine {
  constructor(
    readonly id: string,
    readonly identifier: number,
    readonly name: string,
    readonly internalRef: string,
    readonly priority: string,
    readonly status: Status,
    readonly assignees: User[],
    readonly company: Company,
    readonly contacts: Contact[],
    readonly clientInfo: ClientInfo,
    readonly createdBy: User,
    readonly equipmentData: string,
    readonly internalObservation: string,
    readonly malfunctionType: string,
    readonly workDone: string,
    readonly requests: TicketRequest[],
    readonly photos: string[],
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly actAt: string | null,
    readonly plannedEndAt: string | null,
    readonly executedAt: string | null,
    readonly equipments: MiniEquipment[],
    readonly closedAt: string | null,
    readonly warranty: boolean,
    readonly finished: boolean,
    readonly brand: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly userObservation: string,
    readonly travels: TravelResponse[],
    readonly unregisteredParts: string,
    readonly interventions: InterventionResponse[],
    readonly workPeriods: WorkPeriod[],
    readonly scope: Array<string>,
    readonly interventionType: string,
    readonly category: string
  ) {
    if (name) this.name = name.trim();
    if (internalRef) this.internalRef = internalRef.trim();
    if (priority) this.priority = priority.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (malfunctionType) this.malfunctionType = malfunctionType.trim();
    if (workDone) this.workDone = workDone.trim();
    if (brand) this.brand = brand.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
    if (userObservation) this.userObservation = userObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
  }

  static fromSerialised(serialised: ISerialisedTicketSupportMultimachine) {
    const status = Status.fromSerialised(serialised.status);
    const company = Company.fromSerialised(serialised.company);
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);
    const requests = serialised.requests.map(TicketRequest.fromSerialised);
    const assignees = serialised.assignees.map(User.fromSerialised);
    const contacts = serialised.contacts.map(Contact.fromSerialised);
    const workPeriods = serialised.workPeriods.map(WorkPeriod.fromSerialised);

    return new TicketSupportMultimachine(
      serialised.id,
      serialised.identifier,
      serialised.name,
      serialised.internalRef,
      serialised.priority,
      status,
      assignees,
      company,
      contacts,
      clientInfo,
      User.fromSerialised(serialised.createdBy),
      serialised.equipmentData,
      serialised.internalObservation,
      serialised.malfunctionType,
      serialised.workDone ? serialised.workDone : '',
      requests,
      serialised.photos ? serialised.photos : [],
      serialised.createdAt,
      serialised.updatedAt,
      serialised.actAt ? serialised.actAt : null,
      serialised.plannedEndAt ?? null,
      serialised.executedAt ? serialised.executedAt : null,
      serialised.equipments,
      serialised.closedAt ? serialised.closedAt : null,
      serialised.warranty,
      serialised.finished,
      serialised.brand,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.userObservation,
      serialised.travels,
      serialised.unregisteredParts,
      serialised.interventions,
      workPeriods,
      serialised.scope,
      serialised.interventionType,
      serialised.category
    );
  }

  toSerialised(): ISerialisedTicketSupportMultimachine {
    const statusObj = this.status.toSerialised();
    const companyObj = this.company.toSerialised();
    const clientInfoObj = this.clientInfo.toSerialised();
    const requestsObj = this.requests.map((request) => request.toSerialised());
    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());

    return {
      id: this.id,
      identifier: this.identifier,
      name: this.name,
      internalRef: this.internalRef,
      priority: this.priority,
      status: statusObj,
      assignees: assigneesObj,
      company: companyObj,
      contacts: contactsObj,
      clientInfo: clientInfoObj,
      equipmentData: this.equipmentData,
      internalObservation: this.internalObservation,
      interventions: this.interventions,
      malfunctionType: this.malfunctionType,
      workDone: this.workDone,
      requests: requestsObj,
      photos: this.photos,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      actAt: this.actAt ? this.actAt : null,
      plannedEndAt: this.plannedEndAt ?? null,
      equipments: this.equipments,
      executedAt: this.executedAt ? this.executedAt : null,
      closedAt: this.closedAt ? this.closedAt : null,
      warranty: this.warranty,
      finished: this.finished,
      brand: this.brand,
      model: this.model,
      serialNumber: this.serialNumber,
      type: this.type,
      userObservation: this.userObservation,
      travels: this.travels,
      unregisteredParts: this.unregisteredParts,
      workPeriods: this.workPeriods,
      scope: this.scope,
      interventionType: this.interventionType,
      category: this.category,
      createdBy: this.createdBy,
    };
  }

  toDraft(): UnsavedTicketSupportMultimachine {
    const supportTicket = this.toSerialised();

    return UnsavedTicketSupportMultimachine.fromSerialised({
      ...supportTicket,
      statusId: supportTicket.status.id,
      companyId: supportTicket.company.id,
      contactIds: supportTicket.contacts.map((contact) => contact.id),
      assigneeIds: supportTicket.assignees.map((assignee) => assignee.id),
    });
  }
}
