<mat-autocomplete
  requireSelection
  autoActiveFirstOption
  [displayWith]="displayNameOf"
  #auto="matAutocomplete"
>
  <mat-option *ngFor="let c of choices$ | async" [value]="c">
    {{c.name}}
  </mat-option>
</mat-autocomplete>
<mat-chip-grid #chipList>
  <mat-chip
    *ngFor="let c of displayedSelections$ | async"
    (removed)="remove(c)"
  >
    {{c.name}}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
  <input
    #chipsInput
    matInput
    type="text"
    (blur)="onTouched()"
    (input)="onTextInput($event)"
    [formControl]="internalControl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="confirmKeys"
  />
</mat-chip-grid>
