<div class="timeline-event">
  <div class="timeline-date" [class.completed]="ticket.completed">
    <time>{{ ticket.date | momentTimezoneToAngularTimezone: 'HH:mm' }}</time>
    <span>{{ ticket.date | momentTimezoneToAngularTimezone }}</span>
  </div>
  <mat-icon
    [class.orange-900]="type === TicketType.SUPPORT"
    [class.light-blue-500]="type === TicketType.REQUEST"
    [class.purple-500]="type === TicketType.SALE"
    [class.not-completed]="!ticket.completed"
    matTooltip="{{ type | i18nextCap }}"
    matTooltipClass="historyTooltip"
  >
    {{ Icon[type] }}
  </mat-icon>
  <div class="timeline-info">
    <label
      class="primary-color"
      matTooltip="{{ 'forms:openTicket' | i18nextCap }}"
      matTooltipClass="historyTooltip"
      [disableLinkIfNotAllowed]="ticketPermissions"
      (click)="openTicket()"
    >
      {{ ticket.name }}
    </label>
    <span>{{ type | i18nextCap }}</span>
  </div>
</div>
