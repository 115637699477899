import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  HistoryTicket,
  HistoryTicketType,
} from '@fullyops/data/history/history';
import { TicketType } from '@fullyops/legacy/data';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  PermissionType,
  TicketTypeIcon,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

interface TicketItem extends HistoryTicket {
  readonly completed: boolean;
}

@Component({
  selector: 'crm-history-ticket',
  templateUrl: './history-ticket.component.html',
  styleUrls: ['./history-ticket.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryTicketComponent implements OnInit {
  @Input() ticket: TicketItem;
  @Input() type: HistoryTicketType;
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: HistoryTicketType;
  }>();

  TicketType = TicketType;
  ticketPermissions = [];
  Icon = TicketTypeIcon;

  ngOnInit() {
    switch (this.type) {
      case TicketType.REQUEST:
        this.ticketPermissions.push(
          PermissionType.CAN_ACCESS_REQUEST_TICKETS,
          PermissionType.CAN_ACCESS_OWN_REQUEST_TICKETS
        );
        break;
      case TicketType.SUPPORT:
        this.ticketPermissions.push(PermissionType.CAN_ACCESS_SUPPORT_TICKETS);
        break;
      default:
        break;
    }
  }

  openTicket() {
    this.openTicketEvent.emit({
      ticketId: this.ticket.id,
      ticketType: this.type,
    });
  }
}
