import { Component, Injectable, Input, OnInit } from '@angular/core';
import { HideIfNotAllowedDirective } from '@fullyops/shared/directives/hide-if-not-allowed.directive';
import {
  faChevronLeft,
  faChevronRight,
  faHouse,
} from '@fortawesome/free-solid-svg-icons';
import {
  ActivatedRoute,
  BaseRouteReuseStrategy,
  NavigationEnd,
  Router,
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { I18NextModule } from 'angular-i18next';
import { CommonModule } from '@angular/common';

interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'fo-breadcrumb',
  templateUrl: './fo-breadcrumb.component.html',
  styleUrls: ['./fo-breadcrumb.component.scss'],
  providers: [HideIfNotAllowedDirective],
  standalone: true,
  imports: [RouterModule, FaIconComponent, I18NextModule, CommonModule],
})
export class FoBreadcrumbComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  faChevronRight = faChevronRight;
  faHouse = faHouse;
  breadcrumbs: Breadcrumb[] = [];

  @Input() lastBreadcrumbLabelOverride: String;

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      }
    });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      const hasMoreNestedChildren = child.children.length > 0;
      if (label) {
        breadcrumbs.push({ label, url });
      } else if (!hasMoreNestedChildren && this.lastBreadcrumbLabelOverride) {
        breadcrumbs.push({
          label: this.lastBreadcrumbLabelOverride as string,
          url,
        });
      }

      // Recursively create breadcrumbs for each child
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  protected async navigateToParent() {
    const parentRoute = this.breadcrumbs.at(-2);
    if (!parentRoute) {
      return;
    }

    await this.router.navigateByUrl(parentRoute.url);
  }

  protected readonly faChevronLeft = faChevronLeft;
}
