import { ISerialisedUnsavedTicketSupportMultimachine } from './serialisations/ISerialisedTicketSupportMultimachine';
import { ClientInfo } from './ClientInfo';
import { Travel } from './Travel';
import { WorkPeriod } from './WorkPeriod';
import { MiniEquipment } from '@fullyops/data/equipments/equipment';

export class UnsavedTicketSupportMultimachine {
  constructor(
    readonly name: string,
    readonly internalRef: string,
    readonly priority: string,
    readonly statusId: string,
    readonly assigneeIds: string[],
    readonly companyId: string,
    readonly contactIds: string[],
    readonly clientInfo: ClientInfo,
    readonly equipmentData: string,
    readonly internalObservation: string,
    readonly malfunctionType: string,
    readonly workDone: string,
    readonly photos: string[],
    readonly createdAt: Date,
    readonly actAt: Date | null,
    readonly executedAt: Date | null,
    readonly closedAt: Date | null,
    readonly warranty: boolean,
    readonly finished: boolean,
    readonly brand: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly userObservation: string,
    readonly travels: Travel[],
    readonly unregisteredParts: string,
    readonly workPeriods: WorkPeriod[],
    readonly scope: Array<string>,
    readonly interventionType: string,
    readonly category: string
  ) {
    if (name) this.name = name.trim();
    if (internalRef) this.internalRef = internalRef.trim();
    if (priority) this.priority = priority.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (malfunctionType) this.malfunctionType = malfunctionType.trim();
    if (workDone) this.workDone = workDone.trim();
    if (brand) this.brand = brand.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
    if (userObservation) this.userObservation = userObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
  }

  static fromSerialised(
    serialised: ISerialisedUnsavedTicketSupportMultimachine
  ) {
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);
    const travels = serialised.travels.map(Travel.fromSerialised);
    const workPeriods = serialised.workPeriods.map(WorkPeriod.fromSerialised);

    return new UnsavedTicketSupportMultimachine(
      serialised.name,
      serialised.internalRef,
      serialised.priority,
      serialised.statusId,
      serialised.assigneeIds,
      serialised.companyId,
      serialised.contactIds,
      clientInfo,
      serialised.equipmentData,
      serialised.internalObservation,
      serialised.malfunctionType,
      serialised.workDone,
      serialised.photos,
      serialised.createdAt ? new Date(serialised.createdAt) : null,
      serialised.actAt ? new Date(serialised.actAt) : null,
      serialised.executedAt ? new Date(serialised.executedAt) : null,
      serialised.closedAt ? new Date(serialised.closedAt) : null,
      serialised.warranty,
      serialised.finished,
      serialised.brand,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.userObservation,
      travels,
      serialised.unregisteredParts,
      workPeriods,
      serialised.scope,
      serialised.interventionType,
      serialised.category
    );
  }

  toSerialised(): ISerialisedUnsavedTicketSupportMultimachine {
    const clientInfoObj = this.clientInfo.toSerialised();
    const travelsObj = this.travels.map((travel) => travel.toSerialised());

    return {
      name: this.name,
      internalRef: this.internalRef,
      priority: this.priority,
      statusId: this.statusId,
      assigneeIds: this.assigneeIds,
      companyId: this.companyId,
      contactIds: this.contactIds,
      clientInfo: clientInfoObj,
      equipmentData: this.equipmentData,
      internalObservation: this.internalObservation,
      malfunctionType: this.malfunctionType,
      workDone: this.workDone,
      photos: this.photos,
      createdAt: this.createdAt.toISOString(),
      actAt: this.actAt ? this.actAt.toISOString() : null,
      executedAt: this.executedAt ? this.executedAt.toISOString() : null,
      closedAt: this.closedAt ? this.closedAt.toISOString() : null,
      warranty: this.warranty,
      finished: this.finished,
      brand: this.brand,
      model: this.model,
      serialNumber: this.serialNumber,
      type: this.type,
      userObservation: this.userObservation,
      travels: travelsObj,
      unregisteredParts: this.unregisteredParts,
      workPeriods: this.workPeriods,
      scope: this.scope,
      interventionType: this.interventionType,
      category: this.category,
    };
  }

  clone(
    partialUnsavedTicketSupportMultimachine: PartialUnsavedTicketSupportMultimachine
  ): UnsavedTicketSupportMultimachine {
    const resolve = (key: keyof UnsavedTicketSupportMultimachine) =>
      partialUnsavedTicketSupportMultimachine.hasOwnProperty(key)
        ? partialUnsavedTicketSupportMultimachine[key]
        : this[key];
    return new UnsavedTicketSupportMultimachine(
      resolve('name'),
      resolve('internalRef'),
      resolve('priority'),
      resolve('statusId'),
      resolve('assigneeIds'),
      resolve('companyId'),
      resolve('contactIds'),
      resolve('clientInfo'),
      resolve('equipmentData'),
      resolve('internalObservation'),
      resolve('malfunctionType'),
      resolve('workDone'),
      resolve('photos'),
      resolve('createdAt'),
      resolve('actAt'),
      resolve('executedAt'),
      resolve('closedAt'),
      resolve('warranty'),
      resolve('finished'),
      resolve('brand'),
      resolve('model'),
      resolve('serialNumber'),
      resolve('type'),
      resolve('userObservation'),
      resolve('travels'),
      resolve('unregisteredParts'),
      resolve('workPeriods'),
      resolve('scope'),
      resolve('interventionType'),
      resolve('category')
    );
  }
}

type PartialUnsavedTicketSupportMultimachine = Partial<
  Pick<
    UnsavedTicketSupportMultimachine,
    | 'name'
    | 'internalRef'
    | 'priority'
    | 'statusId'
    | 'assigneeIds'
    | 'companyId'
    | 'contactIds'
    | 'clientInfo'
    | 'travels'
    | 'equipmentData'
    | 'internalObservation'
    | 'malfunctionType'
    | 'workDone'
    | 'photos'
    | 'createdAt'
    | 'actAt'
    | 'executedAt'
    | 'closedAt'
    | 'warranty'
    | 'finished'
    | 'brand'
    | 'model'
    | 'serialNumber'
    | 'type'
    | 'userObservation'
    | 'unregisteredParts'
    | 'workPeriods'
    | 'scope'
    | 'interventionType'
    | 'category'
  >
>;
