import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MaterialModule } from '@fullyops/shared/material.module';
import { normalizeForSearch } from '@fullyops/shared/normalize-for-search';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { MatInput } from '@angular/material/input';

export interface Named {
  name: string;
  icon?: Icon;
}

export interface Icon {
  name: string;
  color: string;
}

@Component({
  standalone: true,
  selector: 'fo-autocompleting-select',
  templateUrl: './template.html',
  imports: [MaterialModule, CommonModule, ReactiveFormsModule],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompletingSelectComponent,
    },
  ],
})
export class AutocompletingSelectComponent<T extends Named>
  implements OnInit, ControlValueAccessor, MatFormFieldControl<any>
{
  @Input() choices: Observable<T[]>;
  @Input() matchPredicate = (v: T, pattern: string) =>
    normalizeForSearch(v.name).indexOf(pattern) !== -1;

  protected choices$: Observable<T[]>;
  protected internalControl = new FormControl<T>(null);
  protected searchText$ = new BehaviorSubject<string>('');

  protected displayNameOf(v: T) {
    return v == null ? '' : v.name;
  }

  ngOnInit() {
    this.choices$ = combineLatest([this.choices, this.searchText$]).pipe(
      map(([allChoices, search]) => {
        var pattern = normalizeForSearch(search as string);
        return allChoices.filter((c) => this.matchPredicate(c, pattern));
      })
    );
    this.internalControl.valueChanges.subscribe((v) => {
      this.searchText$.next('');
      this.onChange(v);
      this.onTouched();
    });
  }

  protected onTextInput(ev: Event) {
    const el = ev.currentTarget as HTMLInputElement;
    this.searchText$.next(el.value);
  }

  // implementation of ControlValueAccessor to enable use with reactive forms

  private onChange = (_: T) => {};
  protected onTouched = () => {};

  writeValue(obj: T): void {
    this.internalControl.setValue(obj);
  }

  registerOnChange(f: (v: T) => void): void {
    this.onChange = f;
  }

  registerOnTouched(f: () => void): void {
    this.onTouched = f;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.internalControl.disable();
    } else {
      this.internalControl.enable();
    }
  }

  // implementation of MatFormFieldControl to enable use inside mat-form-field

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  @ViewChild(MatInput, { static: true })
  protected internalInput: MatInput;

  // These are not used when using this component with reactive forms, but they need to be
  // present regardless.
  get value() {
    return this.internalInput.value;
  }
  set value(s: string) {
    this.internalInput.value = s;
  }

  get stateChanges() {
    return this.internalInput.stateChanges;
  }

  get id() {
    return this.internalInput.id;
  }

  get placeholder() {
    return this.internalInput.placeholder;
  }

  get focused() {
    return this.internalInput.focused;
  }

  get empty(): boolean {
    return this.internalInput.empty;
  }

  get shouldLabelFloat(): boolean {
    return this.internalInput.shouldLabelFloat;
  }

  get required() {
    return this.ngControl.control.hasValidator(Validators.required);
  }

  get disabled() {
    return this.ngControl.disabled;
  }

  get errorState() {
    return this.ngControl.touched && this.ngControl.errors !== null;
  }

  get controlType() {
    return this.internalInput.controlType;
  }

  get autofilled() {
    return this.internalInput.autofilled;
  }

  get userAriaDescribedBy() {
    return this.internalInput.userAriaDescribedBy;
  }

  setDescribedByIds(ids: string[]) {
    this.internalInput.setDescribedByIds(ids);
  }

  onContainerClick(_: MouseEvent) {
    this.internalInput.onContainerClick();
  }
}
