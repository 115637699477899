import { Component, Input } from '@angular/core';
import { OverlappingWorkOrder } from '@fullyops/data/work-orders/work-order';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'fo-work-order-scheduling-issues',
  standalone: true,
  templateUrl: './scheduling-issues.component.html',
  styleUrl: './scheduling-issues.component.scss',
  imports: [I18NextModule],
})
export class WorkOrderSchedulingIssuesComponent {
  @Input() overlaps: OverlappingWorkOrder[];
}
