<mat-autocomplete
  requireSelection
  autoActiveFirstOption
  [displayWith]="displayNameOf"
  #auto="matAutocomplete"
>
  <mat-option *ngFor="let c of choices$ | async" [value]="c">
    @if (c.icon != null) {
    <mat-icon [ngStyle]="{color: c.icon.color}">{{c.icon.name}}</mat-icon>
    } {{ c.name }}
  </mat-option>
</mat-autocomplete>
<input
  type="text"
  matInput
  (blur)="onTouched()"
  (input)="onTextInput($event)"
  [formControl]="internalControl"
  [matAutocomplete]="auto"
/>
