import { ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MaterialModule } from '@fullyops/shared/material.module';
import { normalizeForSearch } from '@fullyops/shared/normalize-for-search';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  filter,
  map,
} from 'rxjs';
import { MatInput } from '@angular/material/input';

export interface Option {
  id: string;
  name: string;
}

export interface Selection {
  id: string;
}

@Component({
  selector: 'fo-autocompleting-select-multiple',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [MaterialModule, CommonModule, ReactiveFormsModule],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompletingSelectMultipleComponent,
    },
  ],
})
export class AutocompletingSelectMultipleComponent<T extends Option>
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<Selection[]>
{
  @Input() choices: Observable<T[]>;
  @Input() matchPredicate = (v: T, pattern: string) =>
    normalizeForSearch(v.name).indexOf(pattern) !== -1;

  protected confirmKeys = [ENTER];

  protected displayedSelections$: Observable<Option[]>;
  protected choices$: Observable<T[]>;
  protected internalControl = new FormControl<T>(null);
  protected searchText$ = new BehaviorSubject<string>('');

  protected displayNameOf(v: T) {
    return v == null ? '' : v.name;
  }

  // selections$ has the current value of this field and emits whenever it is changed,
  // whether programatically or by the user.
  // changes$ only emits on user-initiated changes.
  protected selections$ = new BehaviorSubject<Selection[]>([]);
  protected changes$ = new Subject<Selection[]>();

  private choose(v: T) {
    this.applyUserChange([...this.selections$.value, v]);
    this.internalControl.setValue(null);
    this.searchText$.next('');
  }

  protected remove(v: Selection) {
    this.applyUserChange(this.selections$.value.filter((x) => x.id !== v.id));
  }

  private applyUserChange(newValue: Selection[]) {
    this.selections$.next(newValue);
    this.changes$.next(newValue);
  }

  ngOnInit() {
    const selectionDisplayer = this.choices.pipe(
      map((opts) => {
        const m: { [id: string]: T } = Object.create(null);
        for (const x of opts) {
          m[x.id] = x;
        }
        return (c: Selection) => m[c.id] ?? { id: c.id, name: c.id };
      })
    );
    this.displayedSelections$ = combineLatest([
      selectionDisplayer,
      this.selections$,
    ]).pipe(map(([display, selectedChoices]) => selectedChoices.map(display)));
    this.choices$ = combineLatest([
      this.choices,
      this.selections$,
      this.searchText$,
    ]).pipe(
      map(([allChoices, selectedChoices, search]) => {
        const pattern = normalizeForSearch(search as string);
        return allChoices.filter(
          (c) =>
            !selectedChoices.some((d) => c.id === d.id) &&
            this.matchPredicate(c, pattern)
        );
      })
    );
    this.internalControl.valueChanges
      .pipe(filter((v) => v != null))
      .subscribe((v) => {
        this.choose(v);
        this.onTouched();
      });
  }

  ngOnDestroy() {
    this.valueSub.unsubscribe();
  }

  // implementation of ControlValueAccessor to enable use with reactive forms

  private valueSub = new Subscription();
  protected onTouched = () => {};

  writeValue(obj: Selection[]): void {
    this.selections$.next(obj);
  }

  registerOnChange(f: (v: Selection[]) => void): void {
    this.valueSub.add(this.changes$.subscribe(f));
  }

  registerOnTouched(f: () => void): void {
    this.onTouched = f;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.internalControl.disable();
    } else {
      this.internalControl.enable();
    }
  }

  protected onTextInput(ev: Event) {
    const el = ev.currentTarget as HTMLInputElement;
    this.searchText$.next(el.value);
  }

  // implementation of MatFormFieldControl to enable use inside mat-form-field

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  @ViewChild(MatInput, { static: true })
  protected internalInput: MatInput;

  get value() {
    return this.selections$.value;
  }
  set value(xs: Selection[]) {
    this.selections$.next(xs);
  }

  get stateChanges() {
    return this.internalInput.stateChanges;
  }

  get id() {
    return this.internalInput.id;
  }

  get placeholder() {
    return this.internalInput.placeholder;
  }

  get focused() {
    return this.internalInput.focused;
  }

  get empty(): boolean {
    return this.internalInput.empty && this.selections$.value.length === 0;
  }

  get shouldLabelFloat(): boolean {
    return this.selections$.value.length > 0 || this.searchText$.value !== '';
  }

  get required() {
    return this.ngControl.control.hasValidator(Validators.required);
  }

  get disabled() {
    return this.ngControl.disabled;
  }

  get errorState() {
    return this.ngControl.touched && this.ngControl.errors !== null;
  }

  get controlType() {
    return this.internalInput.controlType;
  }

  get autofilled() {
    return this.internalInput.autofilled;
  }

  get userAriaDescribedBy() {
    return this.internalInput.userAriaDescribedBy;
  }

  setDescribedByIds(ids: string[]) {
    this.internalInput.setDescribedByIds(ids);
  }

  onContainerClick(_: MouseEvent) {
    this.internalInput.onContainerClick();
  }
}
