import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
  FormGroup,
} from '@angular/forms';
import { FormTemplateFields } from '../form-template/form-template.component';
import { TimeZoneDateFormatterService } from '@fullyops/shared/services/date-formatter.service';
import * as moment from 'moment-timezone';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
@Component({
  selector: 'crm-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
})
export class FormDateComponent implements OnInit, OnDestroy {
  constructor(
    private timeZoneDateFormatterService: TimeZoneDateFormatterService,
    protected uiAuthService: UiAuthService
  ) {}

  @Input() dateOnly = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() field: FormTemplateFields<FormGroup<any>>;
  @Output() selectionChange = new EventEmitter();

  selectedHour: any = '';
  timeHours = [];

  currentHour = null;
  currentMinutes = null;

  private sub = new Subscription();

  ngOnInit() {
    this.initHours();

    this.formGroup
      .get(this.field.name as string)
      .valueChanges.subscribe((newDate) => {
        const dateSelected =
          this.timeZoneDateFormatterService.formatInTimezone(newDate);

        if (!this.timeHours.includes(dateSelected.format('HH:mm'))) {
          this.initHours();
        }

        if (!newDate) return (this.selectedHour = null);

        this.selectedHour = this.parseHour(
          this.timeZoneDateFormatterService.formatInTimezone(newDate)
        );
      });

    this.addCustomValidations();
  }

  initHours() {
    const intervals: string[] = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`;
        intervals.push(time);
      }
    }

    const dateSelected = this.timeZoneDateFormatterService.formatInTimezone(
      (this.formGroup.get(this.field.name as string) as UntypedFormControl)
        .value
    );
    if (
      dateSelected != null &&
      dateSelected.isValid() &&
      !intervals.includes(dateSelected.format('HH:mm'))
    ) {
      intervals.push(dateSelected.format('HH:mm'));
      intervals.sort((a, b) => {
        const timeA = moment(a, 'HH:mm');
        const timeB = moment(b, 'HH:mm');
        return timeA.isBefore(timeB) ? -1 : 1;
      });
    }

    if (!isEqual(intervals, this.timeHours)) {
      this.timeHours = intervals;
    }

    this.selectedHour = dateSelected.isValid()
      ? dateSelected.format('HH:mm')
      : null;
  }

  parseHour(data: moment.Moment | Date | string): string {
    let momentData: moment.Moment;

    // Check the input type and convert to moment.Moment
    if (moment.isMoment(data)) {
      momentData = data;
    } else if (data instanceof Date) {
      momentData = moment(data);
    } else if (typeof data === 'string') {
      momentData = moment(data);
    } else {
      throw new Error(
        'Unsupported input format. Use moment.Moment, Date, or string.'
      );
    }

    // Format the time in the desired format (hh:mm)
    const formattedTime: string = momentData.format('HH:mm');
    return formattedTime;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.onSelectChange(event);
  }

  onTimeChange({ value }) {
    (
      this.formGroup.get(this.field.name as string) as UntypedFormControl
    ).markAsDirty();
    (
      this.formGroup.get(this.field.name as string) as UntypedFormControl
    ).markAsTouched();
    this.selectedHour = value;

    const time = value.split(':').map((v) => parseInt(v, 10));

    const date = this.timeZoneDateFormatterService.formatInTimezone(
      (this.formGroup.get(this.field.name as string) as UntypedFormControl)
        .value
    );

    let temp = date.set('hours', time[0]).set('minute', time[1]);

    (
      this.formGroup.get(this.field.name as string) as UntypedFormControl
    ).patchValue(temp.toDate(), { emitEvent: false });
    this.formGroup.markAsDirty();
    this.onSelectChange(value);
    (
      this.formGroup.get(this.field.name as string) as UntypedFormControl
    ).updateValueAndValidity();
  }

  onSelectChange(event: any) {
    this.selectionChange.emit(event);
  }

  getLabelName() {
    if (!this.field.label) {
      return 'forms:chooseTime';
    }
    if (this.field.label == 'endDate') {
      return 'forms:endingHour';
    }

    if (this.field.label == 'startDate') {
      return 'forms:startingHour';
    }

    return 'forms:chooseTime';
  }

  isTimeNotDivisibleBy15 = () => {
    const date = this.timeZoneDateFormatterService.formatInTimezone(
      this.formGroup.controls[this.field.name as string].value
    );
    return date.isValid() && date.minute() % 15 != 0;
  };

  getHourFormattedForSelectInput() {
    const dateAtTimeZone = this.timeZoneDateFormatterService.formatInTimezone(
      (this.formGroup.get(this.field.name as string) as UntypedFormControl)
        .value
    );
    return this.parseHour(dateAtTimeZone);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  addCustomValidations() {
    const initialDateField = this.field.fullDate?.canNotBeBeforeThan;
    if (initialDateField != null) {
      const initialControl = this.formGroup.controls[initialDateField];
      const endControl = this.formGroup.controls[this.field.name];

      endControl.addValidators((control) =>
        validateDateRange(
          initialControl.value,
          control.value,
          'endDateMustBeGreaterThanOrEqualToStartDate'
        )
      );
      // Use a blank message for the initial control, so that we don't
      // show a duplicate error message when the range is invalid.
      initialControl.addValidators((control) =>
        validateDateRange(control.value, endControl.value, 'blank')
      );
      this.sub.add(
        initialControl.valueChanges.subscribe(() => {
          // Mark the other field as touched so that it is visually
          // marked as invalid even if it has not been directly touched.
          endControl.markAsTouched({ onlySelf: true });
          endControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        })
      );
      this.sub.add(
        endControl.valueChanges.subscribe(() => {
          initialControl.markAsTouched({ onlySelf: true });
          initialControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        })
      );
    }
  }
}

function validateDateRange(
  initialDateInput: string,
  endDateInput: string,
  message: string
): ValidationErrors | null {
  const initialDate = moment(initialDateInput);
  const endDate = moment(endDateInput);

  if (
    !(initialDate.isValid() && endDate.isValid()) ||
    endDate.isSameOrAfter(initialDate)
  ) {
    return null;
  }

  return { message };
}
