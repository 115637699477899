<div class="timeline-event">
  <div class="timeline-date" [class.completed]="activity.completed">
    <time>{{
      activity.startsAt | momentTimezoneToAngularTimezone: 'HH:mm'
    }}</time>
    <span>{{ activity.startsAt | momentTimezoneToAngularTimezone }}</span>
  </div>
  <mat-icon
    [class.teal-500]="activity.type === 'MEETING'"
    [class.green-600]="activity.type === 'CALL'"
    [class.amber-500]="activity.type === 'REMINDER'"
    [class.blue-700]="activity.type === 'TASK'"
    [class.red-500]="activity.type === 'EMAIL'"
    [class.not-completed]="!activity.completed"
    matTooltip="{{ 'forms:' + activity.type | i18nextCap }}"
    matTooltipClass="historyTooltip"
  >
    {{ Icon[activity.type] }}
  </mat-icon>
  <div class="timeline-info">
    <label
      class="primary-color"
      matTooltip="{{ 'forms:openActivity' | i18nextCap }}"
      matTooltipClass="historyTooltip"
      [disableLinkIfNotAllowed]="activityPermissions"
      (click)="openActivity(activity.id)"
    >
      {{ activity.name }}
    </label>
    <span *ngIf="!activity.ticket">{{ 'noTicket' | i18nextCap }}</span>
    <span
      *ngIf="activity.ticket"
      class="link"
      matTooltip="{{ 'forms:openTicket' | i18nextCap }}"
      matTooltipClass="historyTooltip"
      [disableLinkIfNotAllowed]="ticketPermissions"
      (click)="openTicket(activity.ticket.id, activity.ticketType)"
    >
      [{{ activity.ticket.identifier | ticketIdentifier: activity.ticketType }}]
      {{ activity.ticket.name }}
    </span>
  </div>
</div>
