import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ticketIdentifier' })
export class TicketIdentifierPipe implements PipeTransform {
  transform(
    value: number | string,
    ticketType?: 'REQUEST' | 'SUPPORT' | 'SALE'
  ): string {
    return ticketType === 'REQUEST'
      ? `R-${value}`
      : ticketType === 'SUPPORT'
      ? `WO-${value}`
      : `${value}`;
  }
}
