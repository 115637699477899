import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  HistoryActivity,
  HistoryTicketType,
} from '@fullyops/data/history/history';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  ActivityTypeIcon,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

interface ActivityItem extends HistoryActivity {
  readonly completed: boolean;
}

@Component({
  selector: 'crm-history-activity',
  templateUrl: './history-activity.component.html',
  styleUrls: ['./history-activity.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryActivityComponent implements OnInit {
  @Input() activity: ActivityItem;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: HistoryTicketType;
  }>();

  protected Icon = ActivityTypeIcon;

  activityPermissions = [
    PermissionType.CAN_ACCESS_ACTIVITIES,
    PermissionType.CAN_ACCESS_OWN_ACTIVITIES,
  ];
  ticketPermissions = [];

  ngOnInit() {
    switch (this.activity.ticketType) {
      case 'REQUEST':
        this.ticketPermissions.push(
          PermissionType.CAN_ACCESS_REQUEST_TICKETS,
          PermissionType.CAN_ACCESS_OWN_REQUEST_TICKETS
        );
        break;
      case 'SUPPORT':
        this.ticketPermissions.push(PermissionType.CAN_ACCESS_SUPPORT_TICKETS);
        break;
      default:
        break;
    }
  }

  openActivity(activityId: string) {
    this.openActivityEvent.emit(activityId);
  }

  openTicket(ticketId: string, ticketType: HistoryTicketType) {
    this.openTicketEvent.emit({ ticketId, ticketType });
  }
}
