import { User } from './User';
import { ISerialisedCompanyAssignee } from './serialisations/ISerialisedCompanyAssignee';
import { Company } from './Company';

export class CompanyAssignee {
  constructor(
    readonly id: string,
    readonly assignee: User,
    readonly company: Company,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly deleted: boolean,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(serialised: ISerialisedCompanyAssignee) {
    if (!serialised) {
      return null;
    }

    const assignee = serialised.assignee
      ? User.fromSerialised(serialised.assignee)
      : null;
    const company = Company.fromSerialised(serialised.company);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new CompanyAssignee(
      '',
      assignee,
      company,
      serialised.createdAt,
      createdBy,
      serialised.deleted,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedCompanyAssignee {
    const assigneeObj = this.assignee.toSerialised();
    const companyObj = this.company.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: '',
      assignee: assigneeObj,
      company: companyObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
      ...this,
    };
  }

  clone(partialTicketSupport: PartialCompanyAssignee): CompanyAssignee {
    const resolve = (key: keyof CompanyAssignee) =>
      partialTicketSupport.hasOwnProperty(key)
        ? partialTicketSupport[key]
        : this[key];

    return new CompanyAssignee(
      resolve('id'),
      resolve('assignee'),
      resolve('company'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('deleted'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialCompanyAssignee = Partial<
  Pick<
    CompanyAssignee,
    | 'id'
    | 'assignee'
    | 'company'
    | 'createdAt'
    | 'createdBy'
    | 'deleted'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
