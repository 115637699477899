import { Inject, Injectable } from '@angular/core';
import {
  API_SERVICE_CONFIG,
  IApiServiceConfig,
} from '../base/configuration/api-service-config';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  OverlappingWorkOrder,
  WorkOrder,
  WorkOrderAssignee,
  WorkOrderStatus,
} from './work-order';
import { ResponseWrapper } from '../base/entities/responses/response-wrapper';

@Injectable({ providedIn: 'root' })
export class ApiWorkOrdersAdapterService {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  get(): Observable<WorkOrder[]> {
    return this.http.get<WorkOrder[]>(
      this.config.url + 'tickets/multimachinesupports/board-items'
    );
  }

  getPotentialAssignees(): Observable<WorkOrderAssignee[]> {
    // Given the long legacy of work orders as support tickets,
    // any user could potentially be an assignee here.
    return this.http.get<WorkOrderAssignee[]>(this.config.url + 'users/names');
  }

  getStatuses(): Observable<WorkOrderStatus[]> {
    return this.http
      .get<ResponseWrapper<WorkOrderStatus>>(
        this.config.url + 'tickets/support/status'
      )
      .pipe(map((res) => res.results));
  }

  getOverlaps(
    assigneeIds: string[],
    startDate: string,
    endDate: string | null
  ): Observable<OverlappingWorkOrder[]> {
    const queryParams: any = { startDate: startDate, assignees: assigneeIds };
    if (endDate != null) {
      queryParams.endDate = endDate;
    }
    return this.http.get<OverlappingWorkOrder[]>(
      this.config.url + 'work-orders/overlapping',
      { params: queryParams }
    );
  }

  updateStatus(id: string, newStatusId: string): Observable<void> {
    return this.update(id, { statusId: newStatusId });
  }

  updateInterval(
    id: string,
    interval: { start: string; end: string }
  ): Observable<void> {
    return this.update(id, {
      actAt: interval.start,
      plannedEndAt: interval.end,
    });
  }

  private update(id: string, change: any): Observable<void> {
    return this.http.patch<void>(
      this.config.url + 'tickets/multimachinesupports/' + id,
      change
    );
  }

  export(ids: string[]): Observable<Blob> {
    return this.http.post(
      this.config.url + 'tickets/multimachinesupports/export',
      ids,
      { responseType: 'blob' }
    );
  }
}
