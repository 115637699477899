import { Injectable } from '@angular/core';
import { ApiEquipmentsAdapterService } from '@fullyops/data/equipments/api-adapter.service';
import { MiniEquipment } from '@fullyops/data/equipments/equipment';
import { createStore } from '@ngneat/elf';
import {
  deleteAllEntities,
  selectAllEntities,
  setEntities,
  withEntities,
} from '@ngneat/elf-entities';

@Injectable({ providedIn: 'root' })
export class EquipmentService {
  private store = createStore(
    { name: 'equipments' },
    withEntities<MiniEquipment>({ idKey: 'id' })
  );

  constructor(private api: ApiEquipmentsAdapterService) {}

  equipments$ = this.store.pipe(selectAllEntities());

  refreshByCompanyId(companyId: string) {
    this.api
      .getAllByCompany(companyId)
      .subscribe((res) => this.store.update(setEntities(res)));
  }

  clear() {
    this.store.update(deleteAllEntities());
  }
}
