import { User } from './User';
import { Anomaly } from './Anomaly';
import { TicketSupportMultimachine } from './TicketSupportMultimachine';
import { ClientEquipment } from './ClientEquipment';
import { ISerialisedTicketSupportMultimachineAnomaly } from './serialisations/ISerialisedTicketSupportMultimachineAnomaly';

export class TicketSupportMultimachineAnomaly {
  constructor(
    readonly id: string,
    readonly anomaly: Anomaly,
    readonly clientEquipment: ClientEquipment,
    readonly deleted: boolean,
    readonly supportTicket: TicketSupportMultimachine,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {}

  static fromSerialised(
    serialised: ISerialisedTicketSupportMultimachineAnomaly
  ) {
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    const anomaly = Anomaly.fromSerialised(serialised.anomaly);
    const clientEquipment = ClientEquipment.fromSerialised(
      serialised.clientEquipment
    );
    const ticketSupport = TicketSupportMultimachine.fromSerialised(
      serialised.supportTicket
    );

    return new TicketSupportMultimachineAnomaly(
      serialised.id,
      anomaly,
      clientEquipment,
      serialised.deleted,
      ticketSupport,
      serialised.createdAt,
      createdBy,
      serialised.updatedAt,
      updatedBy
    );
  }

  clone(
    parcialTicketSupportMultimachineAnomaly: PartialTicketSupportMultimachineAnomaly
  ): TicketSupportMultimachineAnomaly {
    const resolve = (key: keyof TicketSupportMultimachineAnomaly) =>
      parcialTicketSupportMultimachineAnomaly.hasOwnProperty(key)
        ? parcialTicketSupportMultimachineAnomaly[key]
        : this[key];
    return new TicketSupportMultimachineAnomaly(
      resolve('id'),
      resolve('anomaly'),
      resolve('clientEquipment'),
      resolve('deleted'),
      resolve('supportTicket'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialTicketSupportMultimachineAnomaly = Partial<
  Pick<
    TicketSupportMultimachineAnomaly,
    | 'id'
    | 'anomaly'
    | 'clientEquipment'
    | 'deleted'
    | 'supportTicket'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
