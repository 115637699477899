import { NgModule, ApplicationRef, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './i18next';

import { SharedModule } from './shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UiModule } from '@fullyops/legacy/ui';
import { DataModule, AuthEndpoint } from '@fullyops/legacy/data';
import { LoginComponent } from '@fullyops/legacy/pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';

import { NotifyInterceptor } from './core/interceptors/notify.interceptor';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { ResetPasswordComponent } from '@fullyops/legacy/pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '@fullyops/legacy/pages/forgot-password/forgot-password.component';
import { LoginV2Component } from '@fullyops/legacy/pages/loginv2/loginv2.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { DownloadsComponent } from '@fullyops/legacy/pages/downloads/downloads.component';
import { TokenExpiredComponent } from '@fullyops/legacy/pages/token-expired/token-expired.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FeaturesModule } from '@fullyops/features/features.module';
import { GlobalErrorHandler } from '@fullyops/core/errors/global-error-handler';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { NgChartsModule } from 'ng2-charts';

registerLocaleData(localePt);

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule, // Main routes for application
    BrowserAnimationsModule,
    // CoreModule,           // Singleton objects (services, components that are loaded only once, etc.)
    SharedModule,
    LayoutModule, // Shared (multi-instance) objects
    UiModule,
    FeaturesModule,
    DataModule,
    ReactiveFormsModule,
    I18NextModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-5X6X2GQDB5'),
    NgxGoogleAnalyticsRouterModule,
    MatMomentDateModule,
    InfiniteScrollModule,
    NgChartsModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LoginV2Component,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    DownloadsComponent,
    TokenExpiredComponent,
  ],
  providers: [
    I18N_PROVIDERS,
    AuthEndpoint,
    CanDeactivateGuard,
    { provide: HTTP_INTERCEPTORS, useClass: NotifyInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    provideHotToastConfig({
      stacking: 'depth',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
