@if (overlaps.length > 0) {
<div class="warning">
  {{ 'followingWorkOrdersOverlap' | i18nextCap }}
  <ul>
    @for (wo of overlaps; track wo.id) {
    <li>
      @if (wo.accessible) {
      <a href="/tickets/support/{{ wo.id }}/edit" target="_blank">
        WO-{{ wo.identifier }}
      </a>
      } @else { WO-{{ wo.identifier }}
      }
    </li>
    }
  </ul>
</div>
}
