import { Router } from '@angular/router';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  Contact,
  UnsavedContact,
  Company,
  Response,
  TicketType,
} from '@fullyops/legacy/data';
import { UiAuthService } from '@fullyops/legacy/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContactFormService } from './contact-form.service';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import {
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { CompanyResponse } from '@fullyops/legacy/data/api/types/Company';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';
import { HistoryItem } from '@fullyops/data/history/history';

@Component({
  selector: 'crm-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [ContactFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ContactFormComponent
  extends FormComponent<Contact, UnsavedContact, ContactFormService>
  implements OnInit
{
  @Input() preview: boolean;
  @Input() companies$: BehaviorSubject<Company[]>;
  @Input() history$: Observable<HistoryItem[]>;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: TicketType;
  }>();

  infoTabFields: TemplateFormFields<any> = [];
  actions: ActionButtons = {};

  constructor(
    public contactFormService: ContactFormService,
    public toastService: ToastService,
    public authService: UiAuthService,
    protected router: Router,
    public dialog: MatDialog,
    private location: Location
  ) {
    super(toastService, authService);

    this.formService = contactFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_CONTACTS];
    this.draft$ = new BehaviorSubject<UnsavedContact>(
      new UnsavedContact('', '', '', '', '', '', '')
    );
  }

  ngOnInit() {
    this.initForm();

    this.initFormFields();
  }

  initFormFields() {
    this.infoTabFields = [
      {
        name: 'name',
        type: 'text',
        label: 'name',
        size: 6,
      },
      {
        name: 'jobPosition',
        type: 'text',
        label: 'jobPosition',
        size: 6,
      },
      {
        name: 'address',
        type: 'text',
        label: 'address',
        size: 12,
      },
      {
        name: 'email',
        type: 'text',
        label: 'email',
        size: 12,
      },
      {
        name: 'phone',
        type: 'text',
        label: 'phone',
        size: 12,
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'company',
        size: 12,
        items$: this.companies$,
        nullable: false,
        translate: false,
      },
      {
        name: 'notes',
        type: 'textarea',
        label: 'notes',
        size: 12,
      },
    ];

    if (this.entity) this.addRedirectCompanyButtonOnField();
    if (this.entity) this.initCompanyOnAutocomplete();
  }

  initCompanyOnAutocomplete() {
    this.companies$.subscribe((companies) => {
      const company = companies.find(
        (company) => company.id == this.entity.company.id
      );
      this.contactFormService.form$.subscribe((form) => {
        form.controls['company'].setValue(company);
      });
    });
  }

  addRedirectCompanyButtonOnField() {
    const companyIndex = this.infoTabFields.findIndex(
      (fieldObj) => fieldObj.name == 'company'
    );

    this.infoTabFields[0][companyIndex] = {
      ...this.infoTabFields[0][companyIndex],
      button: {
        iconName: 'account_balance',
        onClick: () => this.redirectToCompanyPage(),
      },
    };
  }

  onActionEvent(action) {
    if (action == 'cancel') {
      if (!this.form.touched) return history.back();

      if (this.form.touched) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            message: 'leavePageMessage',
            closeModalText: 'cancel',
            actionModalText: 'leave',
          },
        });
        return dialogRef.afterClosed().subscribe((res) => {
          if (res == true) {
            return this.location.back();
          }
        });
      }
    }

    if (action == 'delete') {
      this.onDelete();
    }
  }

  onOpenActivity(id: string) {
    this.openActivityEvent.emit(id);
  }

  onOpenTicket(ticket: { ticketId: string; ticketType: TicketType }) {
    this.openTicketEvent.emit(ticket);
  }

  onSave() {
    this.onSubmit(this.form);
  }

  redirectToCompanyPage() {
    const company = this.form.controls['company'].value as CompanyResponse;

    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${company.id}${canEditCompanies ? '/edit' : ''}`,
        ]);
      });
  }
}
