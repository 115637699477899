<div class="row">
  <div [class]="{ 'col-md-12': dateOnly, 'col-md-7': !dateOnly }">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
      <input
        (dateChange)="onDateChange($event)"
        matInput
        [matDatepicker]="picker"
        [name]="field.name"
        [formControl]="$any(formGroup.controls[field.name])"
        [matDatepickerFilter]="
          $any(
            field.fullDate?.matDatepickerFilter
              ? field.fullDate.matDatepickerFilter
              : null
          )
        "
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>
        {{
          formGroup.controls[field.name]?.errors
            ? formGroup.controls[field.name]?.errors?.message
              ? ('error:' + formGroup.controls[field.name].errors.message
                | i18nextCap)
              : ('validation:youMustSelectADate' | i18nextCap)
            : ('validation:youMustSelectADate' | i18nextCap)
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div
    *ngIf="!dateOnly"
    [class]="{ 'col-md-5': !dateOnly, 'time-container': true }"
  >
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{ getLabelName() | i18nextCap }}</mat-label>
      <mat-select
        (selectionChange)="onTimeChange($event)"
        [id]="field.name + '_time'"
        [value]="selectedHour"
        [disabled]="
          !(field.fullDate?.hourNeverDisabled == true) &&
          formGroup.controls[field.name].disabled
        "
      >
        <mat-option *ngFor="let hour of timeHours" [value]="hour">
          {{ hour }}
        </mat-option>
        <mat-option
          *ngIf="isTimeNotDivisibleBy15()"
          [value]="getHourFormattedForSelectInput()"
        >
          {{ getHourFormattedForSelectInput() }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{
          formGroup.controls[field.name].errors
            ? formGroup.controls[field.name].errors.message
              ? ('error:' + formGroup.controls[field.name].errors.message
                | i18nextCap)
              : ('validation:youMustSelectADate' | i18nextCap)
            : ('validation:youMustSelectADate' | i18nextCap)
        }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
