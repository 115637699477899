import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { ResponseWrapper } from '@fullyops/data/base/entities/responses/response-wrapper';
import { TokenizedAsset } from '@fullyops/legacy/data/api/types/TokenizedAsset';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UiDownloadsService extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'assets';

  getAssetByToken(token: String, tenantName: string) {
    return this.http.get<TokenizedAsset>(`${this.endpointUrl}/${token}`, {
      headers: { tenantName: tenantName },
    });
  }

  downloadAssetByToken(
    token: String,
    filename: string | null,
    tenantName: string
  ) {
    return this.http
      .get(`${this.endpointUrl}/${token}/download`, {
        responseType: 'blob' as 'json',
        headers: { tenantName: tenantName },
      })
      .pipe(
        map((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (filename != null) {
            downloadLink.setAttribute('download', filename);
          }

          return downloadLink;
        })
      );
  }
}
