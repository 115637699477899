import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
} from '@angular/forms';
import { I18NextPipe } from 'angular-i18next';
import {
  FormTemplateFields,
  TemplateFormFields,
} from '../form-template/form-template.component';
import { SelectOptionType } from '../form-select/form-select.component';
import {
  faScrewdriverWrench,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { pullAt } from 'lodash';
import { debounceTime } from 'rxjs';

export type AutocompleteListItemType = {
  itemData: SelectOptionType;
  quantity: number;
};

type AddItemFormType = FormGroup<{
  newItem: FormControl<SelectOptionType>;
}>;

@Component({
  selector: 'crm-form-autocomplete-list',
  templateUrl: './form-autocomplete-list.component.html',
  styleUrls: ['./form-autocomplete-list.component.scss'],
})
export class AutocompleteListComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() field: FormTemplateFields<FormGroup<any>>;

  formControl: AbstractControl<Array<AutocompleteListItemType>, any>;
  faScrewdriverWrench = faScrewdriverWrench;
  faTrashCan = faTrashCan;

  constructor(
    private i18nextPipe: I18NextPipe,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {}

  addItemFormGroup: AddItemFormType = this.formBuilder.group({
    newItem: this.formBuilder.control(null),
  });

  addItemField: TemplateFormFields<AddItemFormType> = [];

  ngOnInit(): void {
    this.disableFormIfMainFormDisabled();
    this.initFormControl();
    this.initFormFields();
    this.addOnInputChange();
  }

  initFormControl() {
    this.formControl = this.formGroup.controls[this.field.name];
  }

  disableFormIfMainFormDisabled() {
    const setState = (e: FormControlStatus) => {
      if (e == 'DISABLED') this.addItemFormGroup.disable();
      else if (e === 'VALID' || e === 'INVALID' || e === 'PENDING') {
        this.addItemFormGroup.enable();
      }
    };
    setState(this.formGroup.status);

    this.formGroup.statusChanges.subscribe(setState);
  }

  initFormFields() {
    this.addItemField = [
      {
        label: 'add',
        name: 'newItem',
        type: 'autocomplete',
        items$: this.field.items$,
        size: 12,
        autocomplete: {
          onSelect: () => this.onAddItem(),
          firstOption: this.field.autocomplete?.firstOption || undefined,
          getItemsSelected: () =>
            this.formControl?.value?.map(({ itemData }) => itemData),
          onTypeEnter: this.field.autocomplete?.onTypeEnter || undefined,
        },
      },
    ];
  }

  onAddItem() {
    const optionSelected = this.addItemFormGroup.controls.newItem.value;
    const newValue = this.formControl?.value ? [...this.formControl.value] : [];
    newValue.push(
      this.formatToItemData({ itemData: optionSelected, quantity: 1 })
    );
    this.formControl.setValue(newValue);
    this.formControl.markAsDirty();
    this.addItemFormGroup.controls.newItem.setValue(null);
  }

  formatToItemData({
    itemData,
    quantity,
  }: {
    itemData: SelectOptionType;
    quantity: number;
  }) {
    return {
      itemData,
      quantity,
    };
  }

  updateQuantity(index: number, quantity: number) {
    const newValue = [...this.formControl?.value];
    newValue[index].quantity = quantity;
    this.formControl.setValue(newValue);
    this.formControl.markAsDirty();
  }

  removeItem(index: number) {
    const newValue = [...this.formControl?.value];
    pullAt(newValue, index);
    this.formControl.setValue(newValue);
    this.formControl.markAsDirty();
  }

  addOnInputChange() {
    if (this.field.autocomplete?.onInputChange?.callback) {
      const debounceTimeValue =
        this.field.autocomplete?.onInputChange.debounceTime || 300;
      const callback = this.field.autocomplete?.onInputChange?.callback;
      this.addItemFormGroup.valueChanges
        .pipe(
          debounceTimeValue &&
            debounceTime(this.field.autocomplete?.onInputChange.debounceTime)
        )
        .subscribe((e) => {
          callback(e);
        });
    }
  }

  ngOnDestroy() {
    this.formGroup.statusChanges.subscribe().unsubscribe();
  }
}
