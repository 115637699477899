import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { ResponseWrapper } from '@fullyops/data/base/entities/responses/response-wrapper';
import {
  CompanyResponse,
  CompanyType,
  CountryResponse,
} from '@fullyops/legacy/data/api/types/Company';

type Types = 'CLIENT' | 'BOTH' | 'SUPPLIER';

export type QueryParams = {
  addressId?: string;
  assigneeIds?: string[];
  createdBy?: string;
  disjunction?: boolean;
  email?: string;
  externalId?: string;
  includeDeleted?: boolean;
  mobileNumber?: string;
  name?: string;
  nif?: string;
  notes?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'NAME'
    | 'EMAIL'
    | 'EXTERNAL_ID'
    | 'MOBILE_NUMBER'
    | 'NIF'
    | 'ZIP_CODE'
    | 'WEBSITE'
    | 'NOTES'
    | 'TYPE'
    | 'DISTANCE';
  page?: number;
  size?: number;
  type?: Types[];
  updatedBy?: string;
  website?: string;
  zipCode?: string;
};

@Injectable({
  providedIn: 'root',
})
export class CompanyControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'companies';

  getAll(params: { queryParams: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }

    return this.http.get<ResponseWrapper<CompanyResponse>>(
      `${this.endpointUrl}${query}`
    );
  }

  getByNif(params: { nifIdNumber: string; companyType: CompanyType }) {
    return this.http.get<CompanyResponse[]>(
      `${this.endpointUrl}/nif/${params.nifIdNumber}/${params.companyType}`
    );
  }

  getCountries() {
    return this.http.get<CountryResponse>(`${this.endpointUrl}/countries`);
  }

  getCompaniesTypes() {
    return this.http.get<Types[]>(`${this.endpointUrl}/types`);
  }

  getById(params: { id: string }) {
    return this.http.get<CompanyResponse>(`${this.endpointUrl}/${params.id}`);
  }

  getAllActiveCountries() {
    return this.http.get<CountryResponse[]>(`${this.endpointUrl}/countries`);
  }

  downloadImportTemplate() {
    return this.http.post(
      `${this.endpointUrl}/import/download-template`,
      {},
      { responseType: 'text' }
    );
  }

  importByCsv(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${this.endpointUrl}/import`, data);
  }

  exportCsv() {
    return this.http.post(
      `${this.endpointUrl}/export`,
      {},
      { responseType: 'text' }
    );
  }
}
