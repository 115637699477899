import { Component, Input, EventEmitter, Output } from '@angular/core';
import {
  ResponseState,
  ActivityType,
  HistoryType,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { TimeZoneDateFormatterService } from '@fullyops/shared/services/date-formatter.service';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { HistoryItem, HistoryTicketType } from '@fullyops/data/history/history';

@Component({
  selector: 'crm-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryComponent {
  constructor(public timezoneDate: TimeZoneDateFormatterService) {}
  @Input() history$: Observable<HistoryItem[]>;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: HistoryTicketType;
  }>();

  state = ResponseState;
  activityType = ActivityType;
  HistoryType = HistoryType;

  historyPermissions = [PermissionType.CAN_ACCESS_HISTORY];
  faClockRotateLeft = faClockRotateLeft;
}
